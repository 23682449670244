import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import type { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { Avatar, Checkbox, Switch } from "../../../../../components";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
} from "../../../../../components/drawer";
import { useGetClient, useGetLead, useGetProfile } from "../../../../../services";
import { homeState } from "../../../../../state/home";
import { getImageUrl } from "../../../../../utils/image";
import { LinkCreatorHeader } from "./LinkCreatorHeader";

export const LinkCreatorDrawer = ({ children }: PropsWithChildren) => {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error("Organization ID is required");

  const { linkCreatorLeadId } = useSnapshot(homeState);

  const { data: lead } = useGetLead(
    {
      leadID: linkCreatorLeadId ?? "",
      organizationID: organizationId,
    },
    { enabled: !!linkCreatorLeadId }
  );

  const { data: profile } = useGetProfile(
    {
      organizationId,
      profileId: lead?.profileId ?? "",
    },
    {
      enabled: !!lead?.profileId,
    }
  );

  const { data: client } = useGetClient(
    {
      organizationID: organizationId,
      clientId: lead?.clientId ?? "",
    },
    {
      enabled: !!lead?.clientId,
    }
  );

  const handleLinkCreatorClose = () => {
    // TODO: Save link
    homeState.linkCreatorLeadId = null;
  };

  return (
    <Drawer
      direction="right"
      open={!!linkCreatorLeadId}
      onClose={handleLinkCreatorClose}
      shouldScaleBackground={false}
    >
      {children}

      <DrawerContent className="min-w-[80%] max-w-[80%]" direction="right">
        <VisuallyHidden.Root>
          <DrawerTitle>Edit link</DrawerTitle>
          <DrawerDescription>Edit the link for the profile version</DrawerDescription>
        </VisuallyHidden.Root>
        {lead && (
          <>
            <LinkCreatorHeader
              profile={profile}
              client={client}
              handleClose={handleLinkCreatorClose}
            />
            {/* <div className="flex-grow overflow-auto">
              <LinkEditor
                key={profile?.profileId}
                profileId={profile?.profileId!}
                managerProfileId={localStorage.getItem("current-profile-id")!}
                profileLinkId={lead?.profileLinkId}
              />
            </div> */}

            <div className="flex flex-col xl:flex-row gap-8 p-4 sm:px-10 sm:py-12 overflow-y-auto">
              <div className="flex flex-col grow gap-4">
                <div className="flex flex-row gap-4 items-center">
                  <Checkbox />
                  <Avatar
                    alt={
                      typeof profile?.profileData.name === "string"
                        ? profile.profileData.name
                        : "Profile"
                    }
                    imageUrl={
                      profile?.profilePhotoId ? getImageUrl(profile.profilePhotoId) : undefined
                    }
                    className="w-20 h-20"
                  />

                  <div className="flex flex-col">
                    <p className="text-sm font-semibold">
                      {typeof profile?.profileData.name === "string"
                        ? profile.profileData.name
                        : "Profile"}
                    </p>
                    {typeof profile?.profileData.title === "string" && (
                      <p className="text-sm">{profile.profileData.title}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="bg-gray-1 border border-gray-3 border-dashed rounded-3xl grow shrink-0">
                <div className="p-4 flex flex-col gap-4">
                  <div className="flex flex-row gap-4 items-center justify-end">
                    <p className="text-xs">VOCS styling active</p>
                    <Switch />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};
